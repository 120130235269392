import MenuIcon from '@mui/icons-material/Menu'
import { useContext, useState } from 'react'
import SignIn from './SignIn'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import PersonIcon from '@mui/icons-material/Person'
import InventoryIcon from '@mui/icons-material/Inventory'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import ListIcon from '@mui/icons-material/List'
import SettingsIcon from '@mui/icons-material/Settings'
import { AppContext } from '../state/AppContext'

function GlobalHeader() {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
    const [personAnchorEl, setPersonAnchorEl] = useState<SVGSVGElement | null>(null)
    const isPersonOpen = Boolean(personAnchorEl)
    const onToggleDrawer = () => setIsDrawerOpen((prevState) => !prevState)
    const handlePersonClick = (event: React.MouseEvent<SVGSVGElement>) => {
        setPersonAnchorEl(event.currentTarget)
    }
    const handlePersonClose = () => {
        setPersonAnchorEl(null)
    }

    const { state: { user: { sub } } } = useContext(AppContext)

    const drawer = (
        <Box role="presentation" sx={{ p: 2 }}>
            <List sx={{ width: '100%' }}>
                <ListItemButton href='/archive' disabled={!sub}>
                    <ListItemIcon>
                        <InventoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Archive'} secondary={!sub ? '(Sign in required)' : ''}/>
                </ListItemButton>
                <ListItemButton href='/balanceSheet' disabled={!sub}>
                    <ListItemIcon >
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Balance Sheet'} secondary={!sub ? '(Sign in required)' : ''}/>
                </ListItemButton>
            </List>
            
        </Box>
    )

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav">
                <Toolbar 
                    sx={{ justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={onToggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box display='flex' flexDirection='row' >
                        <Link variant='h5' href='/' color='inherit' underline='none'>radix</Link>
                    </Box>
                    <Box>
                        <PersonIcon onClick={handlePersonClick} />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                open={isDrawerOpen}
                onClose={onToggleDrawer}
                ModalProps={{ keepMounted: true }}
            >
                {drawer}
            </Drawer>
            <Menu
                open={isPersonOpen}
                anchorEl={personAnchorEl}
                onClose={handlePersonClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{
                    minWidth: '350px',
                    p: 1
                }}>
                    <SignIn />
                </Box>
                {sub && (
                    <MenuItem 
                        component={Link} 
                        href='/requisitions'
                    >
                        <SettingsIcon sx={{ mr: 1 }}/>
                        Manage my linked accounts
                    </MenuItem>
                )}
            </Menu>
        </Box>
    )
}

export default GlobalHeader
