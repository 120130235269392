import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Auth } from 'aws-amplify'
import { ChangeEvent, FormEvent, useState } from 'react'
import { ISignUpResult } from 'amazon-cognito-identity-js'
import { enqueueSnackbar } from 'notistack'
function SignUp() {
    const [emailError, setEmailError] = useState<string>('')
    const [signUpResponse, setRegistrationResponse] = useState<ISignUpResult>()
    const [signUpError, setRegistrationError] = useState<any>()
    const [isSignUpSuccess, setIsSignUpSuccess] = useState<boolean>(false)
    const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email)
    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailError(isValidEmail(event.target.value) ? '' : 'Email is invalid')
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (isSignUpSuccess) return
        const formData = new FormData(event.currentTarget)

        if (!signUpResponse) {
            const signUpData = {
                username: formData.get('email'),
                password: formData.get('password'),
            }
            Auth.signUp(Object(signUpData))
                .then(res => {
                    setRegistrationResponse(res)
                })
                .catch(e => {
                    enqueueSnackbar({
                        variant: 'error',
                        message: `Error during signing up (${e})`
                    })
                    setRegistrationError(e)
                })
        } else {
            const confirmSignUpData = {
                username: formData.get('email') as string,
                code: formData.get('code') as string,
            }
            Auth.confirmSignUp(confirmSignUpData.username, confirmSignUpData.code)
                .then(res => {
                    if (res == 'SUCCESS') {
                        setIsSignUpSuccess(true)
                    }
                })
                .catch(e => {
                    enqueueSnackbar({
                        variant: 'error',
                        message: `Error during confirming signing up (${e})`
                    })
                })
        }
    }
    
    return (
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign up
            </Typography>
            <Box component="form" 
                noValidate 
                onSubmit={handleSubmit} 
                sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            type="email"
                            onChange={onEmailChange}
                            error={!!emailError}
                            autoFocus
                        />
                        {emailError && (
                            <Typography variant='body2' sx={{ color: 'error.main' }}>
                                {emailError}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                        />
                    </Grid>
                    {signUpError && (
                        <Typography>{signUpError.code}: {signUpError.message}</Typography>
                    )}
                    {signUpResponse && (
                        <Grid item xs={12}>
                            <Typography sx={{ my: 1 }}>
                            Type below the confirmation code that was sent to {signUpResponse?.codeDeliveryDetails.Destination}
                            </Typography>
                            <TextField
                                required
                                fullWidth
                                name="code"
                                inputProps={{ inputMode: 'numeric' }}
                                label="code"
                                id="code"
                                autoComplete="code"
                                autoFocus
                            />
                        </Grid>
                    )}
                </Grid>
                {isSignUpSuccess && (
                    <Typography sx={{ mt: 1 }}>
                        Sign Up success! You can <Link href="/login">sign in</Link> now
                    </Typography>
                )}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSignUpSuccess}
                    sx={{ mt: 3, mb: 2 }}
                >
                    {signUpResponse && 'Confirm Sign Up'}
                    {!signUpResponse && 'Sign Up'}
                </Button>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Link href="/login" variant="body2">
                      Already have an account? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    )
}

export default SignUp
