import { createContext } from 'react'

export type UserContextType = {
    sub: string,
    email: string
}
export const userInitialState: UserContextType = {
    sub: '',
    email: ''
}
export const UserContext = createContext<UserContextType>(userInitialState)

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
      ? {
          type: Key;
        }
      : {
          type: Key;
          payload: M[Key];
        }
  }

export enum UserActionTypes {
    // eslint-disable-next-line no-unused-vars
    SIGN_IN = 'SIGN_IN',
    // eslint-disable-next-line no-unused-vars
    SIGN_OUT = 'SIGN_OUT',
  }

export type UserPayload = {
    [UserActionTypes.SIGN_IN] : UserContextType
    [UserActionTypes.SIGN_OUT]: {}
}

export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>]

export const userReducer = (state: UserContextType, action: UserActions) => {
    switch (action.type) {
    case UserActionTypes.SIGN_IN: {
        return {
            sub: action.payload.sub,
            email: action.payload.email
        }
    }
    case UserActionTypes.SIGN_OUT: {
        return userInitialState
    }
    }
}