import { useQuery } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { QueryResponseBase } from './types'

export const getFXRates = async (quote: string) => {
    return API.get('fx-market-api', 'fxrates', { queryStringParameters: { quote } })
}

export const useGetFXRates = (quote?: string) => {
    return useQuery<QueryResponseBase, Error, any>({
        queryKey: ['get-fx-rates', quote],
        queryFn: () => getFXRates(quote || 'eur'),
        select: r => r.Items
    })
}
