import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import config from './config'
import { Amplify, Auth } from 'aws-amplify'
import App from './components/App'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.ARCHIVE_BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        S3: { prefixResolver: async ({ targetIdentityId }: {targetIdentityId: any }) => (`private/${targetIdentityId}/`) }
    },
    API: {
        endpoints: [
            {
                name: 'go-cardless',
                endpoint: `${config.apiGateway.URL_GO_CARDLESS}/`,
                region: config.apiGateway.REGION,
                custom_header: async () => ({ Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` })
            },
            {
                name: 'balance-sheet',
                endpoint: `${config.apiGateway.URL_LINKED_BALANCE_SHEET_}/`,
                region: config.apiGateway.REGION,
                custom_header: async () => ({ Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` })
            },
            {
                name: 'fx-market-api',
                endpoint: `${config.apiGateway.URL_FX_API}/`,
                region: config.apiGateway.REGION,
                custom_header: async () => ({ Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` })
            },
            {
                name: 'archive',
                endpoint: `${config.apiGateway.URL_ARCHIVE_API}/`,
                region: config.apiGateway.REGION,
                custom_header: async () => ({ Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` })
            },
        ]
    }
})
// Amplify.Logger.LOG_LEVEL = 'DEBUG'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
})

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
