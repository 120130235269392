import { useContext } from 'react'
import { AppContext } from '../state/AppContext'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material'
import SignIn from './SignIn'

function Home() {
    const { state: { user: { sub } } } = useContext(AppContext)

    return (
        <Stack>
            {!sub && (
                <Box display='flex' justifyContent='center' flexDirection='column'>
                    <Typography textAlign='center'>
                        Sign in to get access to app features
                    </Typography>
                    <SignIn />
                </Box>
            )}
            {sub && (
                <Box display='flex' justifyContent='center' sx={{ p: 1 }}>
                    <Button href='/balanceSheet' variant='contained'>Balance Sheet</Button>
                </Box>
            )}
        </Stack>
    )
}

export default Home