import { useMutation, useQuery } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { QueryResponseBase } from './types'
import { COUNTRIES_WITH_AVAILABLE_INSTITUTIONS } from '../consts'

export type Institution = {
    bic: string
    countries: string[] 
    id: string
    logo: string
    name: string
    transaction_total_days: number
}

export type Requisition = {
    /** Format: uuid */
    id?: string;
    /**
     * Created Date
     * Format: date-time
     * @description The date & time at which the requisition was created.
     */
    created?: string | null;
    /**
     * Format: uri
     * @description redirect URL to your application after end-user authorization with ASPSP
     */
    redirect: string | null;
    /**
     * Requisition status
     * @description status of this requisition
     */
    status?: string;
    /** @description an Institution ID for this Requisition */
    institution_id: string;
    /**
     * Format: uuid
     * @description EUA associated with this requisition
     */
    agreement?: string;
    /** @description additional ID to identify the end user */
    reference?: string;
    /** @description array of account IDs retrieved within a scope of this requisition */
    accounts?: readonly string[];
    /** @description A two-letter country code (ISO 639-1) */
    user_language?: string;
    /**
     * Format: uri
     * @description link to initiate authorization with Institution
     * @default https://ob.nordigen.com/psd2/start/3fa85f64-5717-4562-b3fc-2c963f66afa6/{$INSTITUTION_ID}
     */
    link?: string;
    /** @description optional SSN field to verify ownership of the account */
    ssn?: string;
    /**
     * @description option to enable account selection view for the end user
     * @default false
     */
    account_selection?: boolean;
    /**
     * @description enable redirect back to the client after account list received
     * @default false
     */
    redirect_immediate?: boolean;
  };

export const getInstitutions = async (countryCode: string) => {
    if (!COUNTRIES_WITH_AVAILABLE_INSTITUTIONS.find(country => country.countryCode === countryCode)) {
        return []
    }
    return API.get('go-cardless', 'institutionsList', { queryStringParameters: { countryCode } })
}

export const useGetInstitutions = (countryCode: string) => {
    return useQuery<QueryResponseBase, Error, Institution[]>({
        queryKey: ['get-institutions', countryCode],
        queryFn: () => getInstitutions(countryCode),
        enabled: false
    })
}

export const extractRequisition = async (ref: string) => {
    return API.post('go-cardless', 'extractRequisition', { body: { ref } })
}

export const useExtractRequisition = (ref: string) => {
    return useQuery<QueryResponseBase, Error, any>({
        queryKey: ['extract-requisition', ref],
        queryFn: () => extractRequisition(ref),
        enabled: false,
    })
}

export type RequisitionResponse = {
    id: string
    redirect: string
    status: string
    agreements: string
    accounts: string
    reference: string
    user_language: string
    link: string
}

export const createRequisition = async (params: {
    institutionId: string, language: string, country: string, institutionName: string
}) => {
    return API.post('go-cardless', 'createRequisition', { body: { ...params } })
}

export const useCreateRequisition = () => {
    return useMutation<Requisition, Error, {
        institutionId: string, language: string, country: string, institutionName: string
    }>({ mutationFn: (params) => createRequisition(params), })
}

export const refreshLinkedAccountBalances = async () => {
    return API.get('go-cardless', 'balances', { body: {} })
}

export const useRefreshLinkedAccountBalances = () => {
    return useQuery<QueryResponseBase, Error, any>({
        queryFn: () => refreshLinkedAccountBalances(),
        queryKey: ['refresh-linked-accounts-balances'],
        enabled: false
    })
}
export const deleteRequisiton = async (ids: string[]) => {
    return API.post('go-cardless', 'deleteRequisitions', { body: { ids } })
}

export const usedDeleteRequisiton = () => {
    return useMutation<{deletedAccounts: any[]}, Error, string[]>({ mutationFn: (ids) => deleteRequisiton(ids) })
}
