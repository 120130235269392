import Stack from '@mui/material/Stack'
import { useGetAccounts } from '../services/BalanceSheet'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { groupBy } from 'lodash'
import { usedDeleteRequisiton } from '../services/GoCardless'
import Button from '@mui/material/Button'
import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { QueryResponseBase } from '../services/types'

function Requistions () {
    const accounts = useGetAccounts()
    const deleteRequisiton = usedDeleteRequisiton()
    const queryClient = useQueryClient()

    useEffect(() => {
        if (!deleteRequisiton.isSuccess) return

        queryClient.setQueryData<QueryResponseBase>(['get-accounts'], oldData => {
            if (!oldData) return undefined
            const filteredAccounts = {
                ...oldData, 
                Items: (oldData?.Items || []).filter(oldAccount => !deleteRequisiton.data.deletedAccounts.includes(oldAccount.id))
            }
            return filteredAccounts
        })
    }, [deleteRequisiton])

    if (accounts.isLoading) return (<Typography>Getting your linked accounts</Typography>)

    if (!accounts.isLoading && !accounts.data) {
        return (<Typography>No linked accounts</Typography>)
    }

    const accountsGroupped = groupBy(accounts.data.filter(account => account.requisitionId), 'requisitionId')

    const onSeverLinkClick = (requisitionId: string) => {
        deleteRequisiton.mutate([requisitionId])
    }

    return (
        <Box>
            <Typography variant='h4' textAlign='center'>Linked accounts</Typography>
            {Object.entries(accountsGroupped).map(accountGroup => {
                const requisitionId = accountGroup[0]
                const accounts = accountGroup[1]
                return (
                    <Stack key={requisitionId} sx={{ mb: 3, }}>
                        <Paper 
                            sx={{ p: 1, }}>
                            <Box display='flex' 
                                flexDirection='row'
                                alignItems='center'
                                justifyContent='space-between'
                            >
                                <Typography variant='h6' 
                                    textAlign='center'
                                >
                                    Linked {accounts[0].institutionName} accounts
                                </Typography>
                                    
                            </Box>
                        </Paper>
                        {accounts.map(account => (
                            <Paper 
                                key={account.id}
                                sx={{
                                    p: 1,
                                    my: 1
                                }}>
                                <Box display='flex' 
                                    flexDirection='row' 
                                    alignItems='center'
                                    justifyContent='end'
                                >
                                    <Typography>{account.iban} {account.product && (`(${account.product})`)}</Typography>
                                </Box>
                            </Paper>
                        ))}
                        <Paper>
                            <Button
                                variant='contained'
                                color='error'
                                fullWidth
                                disabled={deleteRequisiton.isLoading}
                                onClick={() => onSeverLinkClick(requisitionId)}
                            >
                                        Sever link with {accounts[0].institutionName} and remove account data
                            </Button>
                        </Paper>
                    </Stack>
                )
            })      
            }
                
        </Box>
    )
}

export default Requistions