import React, { useContext, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { AppContext } from '../state/AppContext'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Link from '@mui/material/Link'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { UserActionTypes } from '../state/UserContext'

function SignIn () {
    const navigate = useNavigate()
    const { state, dispatch } = useContext(AppContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
   
    const onLogin = async (username: string, password: string) => {
        try {
            await Auth.signIn(username, password)
        } catch (e: any) {
            enqueueSnackbar({
                variant: 'error',
                message: `Error during signing in (${e})`
            })
        }
    }

    const onLogOut = async () => {
        try {
            await Auth.signOut()
        } catch (e: any) {
            enqueueSnackbar({
                variant: 'error',
                message: `Error during signing out (${e})`
            })
        }
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        onLogin(email, password)
    }

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }
    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    Hub.listen('auth', (data) => {
        switch (data.payload.event) {
        case 'signIn':
            dispatch({
                type: UserActionTypes.SIGN_IN, payload: {
                    sub: data.payload.data.attributes.sub, 
                    email:data.payload.data.attributes.email
                }
            })
            break
        case 'signOut':
            dispatch({ type: UserActionTypes.SIGN_OUT, payload: {} })
            navigate('/')
            break
        }
    })

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 1,
            width: '100%'
        }}>
            {!state.user.email && (
                <>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                        <Box component="form" 
                            onSubmit={onSubmit} 
                            noValidate 
                            display='flex'
                            flexDirection='column'
                            sx={{
                                mt: 1,
                                width: '100%'
                            }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={onEmailChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={onPasswordChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                            Sign In
                            </Button>
                            {/* <Link href="#" variant="body2">
                        Forgot password?
                            </Link> */}
                           
                            <Link href="/signup" variant="body2">
                                {'Don\'t have an account? Sign Up'}
                            </Link>
                        </Box>
                    </Box>
                </>
            )}
            {state.user.email && (
                <>
                    <Typography component='span' sx={{ m: 1 }}>
                        Signed in as {state.user.email}
                    </Typography>
                    <Button variant="contained" onClick={onLogOut}>Sign out</Button> 
                </>
            )}
            
        </Box>
        
    )
}

export default SignIn
