import { Dispatch, ReactNode, createContext, useReducer } from 'react'
import { UserActions, UserContextType, userInitialState, userReducer } from './UserContext'

type AppContextType = {
    state: {
        user: UserContextType
    };
    dispatch: Dispatch<UserActions>
}

const appInitialState = { user: userInitialState }

const AppContext = createContext<AppContextType>({
    state: appInitialState,
    dispatch: () => null
})

const appReducer = ({ user }: {user: UserContextType}, action: UserActions) => ({ user: userReducer(user, action) })

function AppProvider ({ children } : {children: ReactNode}) {
    const [state, dispatch] = useReducer(
        appReducer,
        appInitialState
    )
    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export { AppContext, AppProvider }