import { useMutation, useQuery } from '@tanstack/react-query'
import { API, Storage } from 'aws-amplify'
import { QueryResponseBase } from './types'

export type ArchiveDocument = {
    createdAt: string
    id: string
    userId: string
    federatedId: string
    thumbKey: string
    type: string
    fields: Record<string, any>[]
}

export type ArchiveDocumentWithUrl = ArchiveDocument & {
    url: string
    thumbUrl: string,
    uploadProgress: number
}

export async function s3Upload(file: File) {
    const filename = `${new Date().toISOString()}-${file.name}`
    const stored = await Storage.vault.put(filename, file, { contentType: file.type, })
    return stored
}

export const postDocuments = async (params: any) => {
    return API.post('archive', 'document', { body: { documents: [params] } })
}

export const usePostDocuments = () => {
    return useMutation<{documents: ArchiveDocument[]}, Error, {
        key: string,
        contentType: string
    }>({ mutationFn: (params) => postDocuments(params), })
}

export const getDocuments = async () => {
    return API.get('archive', 'documents', {})
}

export const useGetDocuments = () => {
    return useQuery<QueryResponseBase, Error, ArchiveDocument[]>({
        queryKey: ['get-documents'],
        queryFn: () => getDocuments(),
        select: r => r.Items
    })
}