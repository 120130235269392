import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { COUNTRIES_LIST } from '../consts'
import { useGetInstitutions } from '../services/GoCardless'
import { useEffect } from 'react'
import { usePostAccounts } from '../services/BalanceSheet'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import { useQueryClient } from '@tanstack/react-query'

function AccountForm() {
    type Inputs = {
        product: string
        country: string
        institutionName: string
        iban: string
        currency: string
        balance: number   
        description: string
        type: string
    }

    const {
        control,
        handleSubmit,
        watch,
        setValue
    } = useForm<Inputs>({ mode: 'onBlur' })
    const watchAll = watch()
    const watchCountry = watch('country')

    const queryClient = useQueryClient()
    const getInstitutions = useGetInstitutions(watchCountry as string)
    const postAccounts = usePostAccounts()
    
    const AVAILABLE_CURRENCIES = [...new Set(COUNTRIES_LIST
        .map(({ currencyCode }) => currencyCode))
    ]

    useEffect(() => {
        if (watchCountry) getInstitutions.refetch()
        const foundCurrency = COUNTRIES_LIST.find(country => country.countryCode === watchCountry)?.currencyCode
        if (foundCurrency) {
            setValue('currency', foundCurrency)
        }
    }, [watchCountry])

    useEffect(() => {
        if (postAccounts.isSuccess) {
            queryClient.invalidateQueries(['get-accounts'])
            postAccounts.reset()
        }
    }, [postAccounts])
    

    const onSubmit: SubmitHandler<Inputs> = () => {
        postAccounts.mutate({ accounts: [watchAll] })
    }

    return (
        <Box component='form' onSubmit={handleSubmit(onSubmit)}>
            <Typography variant='h5' textAlign='center'>Add an account</Typography>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                    <Controller
                        name={'product'}
                        defaultValue=''
                        rules={{ required: 'This field is required' }}
                        control={control}
                        render={({
                            field,
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                required
                                helperText={error ? error.message : 'How do you want this account to be called?'}
                                size="small"
                                error={!!error}
                                fullWidth
                                label='Account Name'
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        name={'iban'}
                        defaultValue=''
                        control={control}
                        render={({
                            field,
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                helperText={error ? error.message : null}
                                size="small"
                                error={!!error}
                                fullWidth
                                label='Account number'
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name={'description'}
                        defaultValue=''
                        control={control}
                        render={({
                            field,
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                multiline
                                minRows={2}
                                helperText={error ? error.message : null}
                                size="small"
                                error={!!error}
                                fullWidth
                                label='Account description'
                                variant="standard"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        control={control}
                        name="country"
                        render={({ field: { ref, onChange, ...field } }) => (
                            <>
                                <Autocomplete 
                                    getOptionLabel={(option) => option.countryName}
                                    id="country-select"
                                    autoSelect
                                    options={COUNTRIES_LIST}
                                    onChange={(_, data) => onChange(data?.countryCode)}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...field}
                                            {...params}
                                            inputRef={ref} 
                                            label='Country'
                                            id="country-select"
                                            size="small"
                                            variant="standard"
                                        />
                                    )}
                                />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Controller
                        control={control}
                        name="institutionName"
                        render={({ field: { ref, onChange, ...field } }) => (
                            <>
                                <Autocomplete 
                                    freeSolo
                                    getOptionLabel={(option) => typeof option === 'string' ? option: option.name}
                                    id="instituition-select"
                                    loading={getInstitutions.isLoading}
                                    loadingText='Loading banks...'
                                    noOptionsText='Could not get banks in selected country'
                                    options={getInstitutions.data || []}
                                    onInputChange={(_, value) => onChange(value)}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...field}
                                            {...params}
                                            inputRef={ref} 
                                            label={
                                                watchCountry && getInstitutions.isLoading ? 
                                                    `Loading banks in ${watchCountry}...` 
                                                    : 'Bank'
                                            }
                                            id="institution-select"
                                            size="small"
                                            variant="standard"
                                        />
                                    )}
                                />
                            </>
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Controller
                        name={'balance'}
                        defaultValue={0}
                        control={control}
                        rules={{
                            pattern: {
                                value: /^(([1-9]*)|(([0-9]*)\.([0-9]*)))$/, 
                                message: 'Use numbers and . only'
                            }
                        }}
                        render={({
                            field,
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                helperText={error ? error.message : null}
                                size="small"
                                error={!!error}
                                fullWidth
                                label='Account Balance'
                                variant="standard"
                                inputProps={{ inputMode: 'numeric' }}
                            />
                        )}
                    />
                </Grid>
            

                <Grid item xs={12} sm={6}>
                    <Controller
                        control={control}
                        name="currency"
                        render={({ field: { ref, onChange, ...field } }) => (
                            <>
                                <Autocomplete 
                                    id="currency-select"
                                    options={AVAILABLE_CURRENCIES}
                                    autoSelect
                                    onChange={(_, data) => onChange(data)}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params}
                                            {...field}
                                            inputRef={ref} 
                                            label='Currency'
                                            size="small"
                                            variant="standard"
                                        />
                                    )}
                                />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth type='submit' variant='contained' 
                        disabled={postAccounts.isLoading}
                    >
                        {postAccounts.isLoading ? 'Saving account...' : 'Add account'}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
    
}

export default AccountForm
