import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useGetBalances, useGetAccounts, usePostBalances, useDeleteBalances, useDeleteAccounts } from '../services/BalanceSheet'
import { groupBy, mapKeys } from 'lodash'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import LinkIcon from '@mui/icons-material/Link'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useRefreshLinkedAccountBalances } from '../services/GoCardless'
import { enqueueSnackbar } from 'notistack'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import { useGetFXRates } from '../services/FXMarketAPI'
import { QueryResponseBase } from '../services/types'

function Accounts() {
    const queryClient = useQueryClient()
    const [openBalanceUpdate, setOpenBalanceUpdate] = useState<string>('')
    const [newBalance, setNewBalance] = useState<string>('0')
    const [deleteMode, setDeleteMode] = useState<boolean>(false)
    const [accountToBeDeleted, setAccountToBeDeleted] = useState<any | null>()
    const accounts = useGetAccounts()
    const getBalances = useGetBalances()
    const postBalances = usePostBalances()
    const deleteBalances = useDeleteBalances()
    const deleteAccounts = useDeleteAccounts()
    const fxRates = useGetFXRates()
    const refreshLinkedAccountBalances = useRefreshLinkedAccountBalances()

    useEffect(() => {
        if (!postBalances.isSuccess) return

        queryClient.setQueryData<QueryResponseBase>(['get-balances'], oldData => {
            if (!oldData) return undefined
            const mergedBalances = {
                ...oldData, 
                Items: [
                    ...(oldData?.Items || []), 
                    ...postBalances.data.balances
                ] 
            }
            return mergedBalances
        })

        postBalances.reset()
        setOpenBalanceUpdate('')
    }, [postBalances])

    useEffect(() => {
        if (!refreshLinkedAccountBalances.isSuccess) return
        if (refreshLinkedAccountBalances.data.errors) {
            const numberOfErrors = refreshLinkedAccountBalances.data.errors.length
            enqueueSnackbar({
                variant: 'error',
                message: `${numberOfErrors} accounts balances could not be refreshed.`
            })
            return
        }
        
        queryClient.setQueryData<QueryResponseBase>(['get-balances'], oldData => {
            if (!oldData) return undefined
            const mergedBalances = {
                ...oldData, 
                Items: [
                    ...(oldData?.Items || []), 
                    ...refreshLinkedAccountBalances.data.balances
                ] 
            }
            return mergedBalances
        })
        
        enqueueSnackbar({
            variant: 'success',
            message: 'Balances will refreshed in a moment!'
        })
    }, [refreshLinkedAccountBalances.isSuccess])

    useEffect(() => {
        if (!(deleteBalances.isSuccess && deleteAccounts.isSuccess)) return

        queryClient.invalidateQueries(['get-balances'])
        queryClient.invalidateQueries(['get-accounts'])
        deleteBalances.reset()
        deleteAccounts.reset()
        setAccountToBeDeleted(null)
        enqueueSnackbar({ variant: 'success', message: 'Account deleted!' })
    }, [deleteBalances, deleteAccounts])


    if (accounts.isLoading || getBalances.isLoading) return <p>Loading accounts...</p>
    if (accounts.isError) return <p>Errow while loading accounts</p>
    if (accounts.isSuccess && !accounts.data.length) return <p>You haven't added any accounts so far.</p>

    const merged = accounts.data
        .map(account => {
            const balances = getBalances.data?.filter(balance => balance.accountId === account.id) as any[]
            const latestBalance = balances.length 
                ? balances.sort((a,b) => (+new Date(b.createdAt)) - (+new Date(a.createdAt)))[0] 
                : { balanceAmount: { amount: 0, currency: account.currency } }
            return {
                ...account, 
                balances,
                balanceAmount: latestBalance.balanceAmount.amount,
                balanceCurrency: latestBalance.balanceAmount.currency
            }
        })

    const componentsGroupped = mapKeys(groupBy(merged, 'country'), (v, k) => k === 'undefined' ? 'Unknown' : k)

    const onUpdateBalanceClick = (account: any) => {
        setOpenBalanceUpdate(account.id)
        setNewBalance(account.balances?.[0]?.balanceAmount?.amount ?? 0)
    }

    const onNewBalanceChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewBalance(event.target.value)
    }

    const onSaveNewBalance = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        postBalances.mutate({
            balanceAmount: newBalance,
            balanceType: 'manual',
            accountId: openBalanceUpdate,
            currency: accounts.data.find(acc => acc.id === openBalanceUpdate).currency
        })
    }

    const onGetCurrentBalancesOfLinkedAccounts = () => {
        refreshLinkedAccountBalances.refetch()
    }

    const onDeleteAccountsClick = () => setDeleteMode (!deleteMode)

    const onDeleteAccountClick = (a: any) => {
        setAccountToBeDeleted(a)
    }

    const handleDeleteConfirmationClose = () => {
        setAccountToBeDeleted(null)
    }

    const onConfirmDeleteAccount = () => {
        deleteBalances.mutate({ accountIds: accountToBeDeleted.id })
        deleteAccounts.mutate({ accountIds: [accountToBeDeleted.id] })
    }

    return (
        <Box>
            <Box display='flex' justifyContent='space-between'>
                {merged.length > 0 && (
                    <Button
                        variant='outlined' color={deleteMode ? 'primary' : 'error'}
                        onClick={onDeleteAccountsClick}>
                        {deleteMode ? 'Update balances': 'Delete accounts'}
                    </Button>
                )}
                {merged.filter(a => a.requisitionId).length > 0 && (
                    <Button variant='contained' 
                        disabled={refreshLinkedAccountBalances.isFetching}
                        onClick={onGetCurrentBalancesOfLinkedAccounts}>
                        {refreshLinkedAccountBalances.isFetching ? 'Refreshing balances, it may take a moment...' : 'Get current balances of linked accounts'}
                    </Button>
                )}
            </Box>
            {Object.entries(componentsGroupped).map((group) => {
                const groupName = group[0]
                const accounts = group[1]
                const sum = accounts.reduce((prev: number, curr) => {
                    if (!fxRates.data) return 0
                    const fxRate = fxRates.data.find((rate: any) => rate.base === curr.balanceCurrency)?.rate || 1
                    const balanceInPreferredCurrency = +curr.balanceAmount * fxRate
                    const toReturn = +(prev + balanceInPreferredCurrency).toFixed(2)
                    return toReturn
                }, 0)
                return (
                    <Box key={groupName}>
                        <Typography>{groupName} {fxRates.data && `(${sum} EUR)`}</Typography>
                        <Stack>
                            {accounts.map(account => (
                                <Paper 
                                    key={account.id}
                                    sx={{ p: 1,my: 1 }}>
                                    <Box display='flex' flexDirection='row' alignItems='center'>
                                        <Typography>
                                            {account.product ? account.product : 'Account'} at {account.institutionName}: {account.balanceAmount} {account.balanceCurrency}
                                        </Typography>
                                        {account.requisitionId && (
                                            <Tooltip title='This account is linked'>
                                                <LinkIcon sx={{ mx: 1 }} />
                                            </Tooltip>
                                        )}
                                        <Box sx={{ ml: 'auto' }} >
                                            {deleteMode && (
                                                <Button variant='outlined' color='error'
                                                    onClick={() => onDeleteAccountClick(account)}>
                                                    Delete
                                                </Button>
                                            )}
                                            {!deleteMode && openBalanceUpdate !== account.id && (
                                                <Button variant='outlined' 
                                                    onClick={() => onUpdateBalanceClick(account)}
                                                >
                                            Update balance
                                                </Button>
                                            )}
                                            {openBalanceUpdate === account.id && (
                                                <Box component='form' onSubmit={onSaveNewBalance}>
                                                    <TextField
                                                        autoFocus
                                                        value={newBalance}
                                                        onChange={onNewBalanceChange}
                                                        size='small'
                                                        label='New Balance'
                                                        inputProps={{ inputMode: 'numeric' }}
                                                        InputProps={{ sx: { pb: .3 } }}
                                                        disabled={postBalances.isLoading}
                                                    />
                                                    <Button variant='outlined' 
                                                        size='large'
                                                        type='submit'
                                                        disabled={postBalances.isLoading}
                                                    >
                                                Save
                                                    </Button>
                                                    <Button variant='outlined' 
                                                        size='large' 
                                                        color='error'
                                                        disabled={postBalances.isLoading}
                                                        onClick={() => setOpenBalanceUpdate('')}
                                                    >
                                                Cancel
                                                    </Button>
                                                </Box>
                                            )} 
                                        </Box>
                                        
                                    </Box>
                                    
                                </Paper>
                            ))}
                        </Stack>
                    </Box>
                )
            })}
            <Dialog
                open={!!accountToBeDeleted}
                onClose={handleDeleteConfirmationClose}
                aria-labelledby="alert-delete-account-title"
                aria-describedby="alert-delete-account-description"
            >
                <DialogTitle id="alert-delete-account-title">
                    Delete {accountToBeDeleted?.product ? accountToBeDeleted.product : 'Account'} at {accountToBeDeleted?.institutionName}?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-delete-account-description">
            All data about this account and balances will be removed. {accountToBeDeleted?.id}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{
                    display: 'flex', justifyContent: 'space-between', px: '14px' 
                }}>
                    <Button onClick={handleDeleteConfirmationClose}>Keep account</Button>
                    <Button onClick={onConfirmDeleteAccount} autoFocus color='error'>
            Delete account
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
    
}

export default Accounts
