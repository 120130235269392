import acFlag from './../flags/ac.svg'
import adFlag from './../flags/ad.svg'
import aeFlag from './../flags/ae.svg'
import afFlag from './../flags/af.svg'
import agFlag from './../flags/ag.svg'
import aiFlag from './../flags/ai.svg'
import alFlag from './../flags/al.svg'
import amFlag from './../flags/am.svg'
import aoFlag from './../flags/ao.svg'
import aqFlag from './../flags/aq.svg'
import arFlag from './../flags/ar.svg'
import arabFlag from './../flags/arab.svg'
import asFlag from './../flags/as.svg'
import atFlag from './../flags/at.svg'
import auFlag from './../flags/au.svg'
import awFlag from './../flags/aw.svg'
import axFlag from './../flags/ax.svg'
import azFlag from './../flags/az.svg'
import baFlag from './../flags/ba.svg'
import bbFlag from './../flags/bb.svg'
import bdFlag from './../flags/bd.svg'
import beFlag from './../flags/be.svg'
import bfFlag from './../flags/bf.svg'
import bgFlag from './../flags/bg.svg'
import bhFlag from './../flags/bh.svg'
import biFlag from './../flags/bi.svg'
import bjFlag from './../flags/bj.svg'
import blFlag from './../flags/bl.svg'
import bmFlag from './../flags/bm.svg'
import bnFlag from './../flags/bn.svg'
import boFlag from './../flags/bo.svg'
import bqFlag from './../flags/bq.svg'
import brFlag from './../flags/br.svg'
import bsFlag from './../flags/bs.svg'
import btFlag from './../flags/bt.svg'
import bvFlag from './../flags/bv.svg'
import bwFlag from './../flags/bw.svg'
import byFlag from './../flags/by.svg'
import bzFlag from './../flags/bz.svg'
import caFlag from './../flags/ca.svg'
import ccFlag from './../flags/cc.svg'
import cdFlag from './../flags/cd.svg'
import ceftaFlag from './../flags/cefta.svg'
import cfFlag from './../flags/cf.svg'
import cgFlag from './../flags/cg.svg'
import chFlag from './../flags/ch.svg'
import ciFlag from './../flags/ci.svg'
import ckFlag from './../flags/ck.svg'
import clFlag from './../flags/cl.svg'
import cmFlag from './../flags/cm.svg'
import cnFlag from './../flags/cn.svg'
import coFlag from './../flags/co.svg'
import cpFlag from './../flags/cp.svg'
import crFlag from './../flags/cr.svg'
import cuFlag from './../flags/cu.svg'
import cvFlag from './../flags/cv.svg'
import cwFlag from './../flags/cw.svg'
import cxFlag from './../flags/cx.svg'
import cyFlag from './../flags/cy.svg'
import czFlag from './../flags/cz.svg'
import deFlag from './../flags/de.svg'
import dgFlag from './../flags/dg.svg'
import djFlag from './../flags/dj.svg'
import dkFlag from './../flags/dk.svg'
import dmFlag from './../flags/dm.svg'
import doFlag from './../flags/do.svg'
import dzFlag from './../flags/dz.svg'
import eacFlag from './../flags/eac.svg'
import ecFlag from './../flags/ec.svg'
import eeFlag from './../flags/ee.svg'
import egFlag from './../flags/eg.svg'
import ehFlag from './../flags/eh.svg'
import erFlag from './../flags/er.svg'
import esFlag from './../flags/es.svg'
import etFlag from './../flags/et.svg'
import euFlag from './../flags/eu.svg'
import fiFlag from './../flags/fi.svg'
import fjFlag from './../flags/fj.svg'
import fkFlag from './../flags/fk.svg'
import fmFlag from './../flags/fm.svg'
import foFlag from './../flags/fo.svg'
import frFlag from './../flags/fr.svg'
import gaFlag from './../flags/ga.svg'
import gbFlag from './../flags/gb.svg'
import gdFlag from './../flags/gd.svg'
import geFlag from './../flags/ge.svg'
import gfFlag from './../flags/gf.svg'
import ggFlag from './../flags/gg.svg'
import ghFlag from './../flags/gh.svg'
import giFlag from './../flags/gi.svg'
import glFlag from './../flags/gl.svg'
import gmFlag from './../flags/gm.svg'
import gnFlag from './../flags/gn.svg'
import gpFlag from './../flags/gp.svg'
import gqFlag from './../flags/gq.svg'
import grFlag from './../flags/gr.svg'
import gsFlag from './../flags/gs.svg'
import gtFlag from './../flags/gt.svg'
import guFlag from './../flags/gu.svg'
import gwFlag from './../flags/gw.svg'
import gyFlag from './../flags/gy.svg'
import hkFlag from './../flags/hk.svg'
import hmFlag from './../flags/hm.svg'
import hnFlag from './../flags/hn.svg'
import hrFlag from './../flags/hr.svg'
import htFlag from './../flags/ht.svg'
import huFlag from './../flags/hu.svg'
import icFlag from './../flags/ic.svg'
import idFlag from './../flags/id.svg'
import ieFlag from './../flags/ie.svg'
import ilFlag from './../flags/il.svg'
import imFlag from './../flags/im.svg'
import inFlag from './../flags/in.svg'
import ioFlag from './../flags/io.svg'
import iqFlag from './../flags/iq.svg'
import irFlag from './../flags/ir.svg'
import isFlag from './../flags/is.svg'
import itFlag from './../flags/it.svg'
import jeFlag from './../flags/je.svg'
import jmFlag from './../flags/jm.svg'
import joFlag from './../flags/jo.svg'
import jpFlag from './../flags/jp.svg'
import keFlag from './../flags/ke.svg'
import kgFlag from './../flags/kg.svg'
import khFlag from './../flags/kh.svg'
import kiFlag from './../flags/ki.svg'
import kmFlag from './../flags/km.svg'
import knFlag from './../flags/kn.svg'
import kpFlag from './../flags/kp.svg'
import krFlag from './../flags/kr.svg'
import kwFlag from './../flags/kw.svg'
import kyFlag from './../flags/ky.svg'
import kzFlag from './../flags/kz.svg'
import laFlag from './../flags/la.svg'
import lbFlag from './../flags/lb.svg'
import lcFlag from './../flags/lc.svg'
import liFlag from './../flags/li.svg'
import lkFlag from './../flags/lk.svg'
import lrFlag from './../flags/lr.svg'
import lsFlag from './../flags/ls.svg'
import ltFlag from './../flags/lt.svg'
import luFlag from './../flags/lu.svg'
import lvFlag from './../flags/lv.svg'
import lyFlag from './../flags/ly.svg'
import maFlag from './../flags/ma.svg'
import mcFlag from './../flags/mc.svg'
import mdFlag from './../flags/md.svg'
import meFlag from './../flags/me.svg'
import mfFlag from './../flags/mf.svg'
import mgFlag from './../flags/mg.svg'
import mhFlag from './../flags/mh.svg'
import mkFlag from './../flags/mk.svg'
import mlFlag from './../flags/ml.svg'
import mmFlag from './../flags/mm.svg'
import mnFlag from './../flags/mn.svg'
import moFlag from './../flags/mo.svg'
import mpFlag from './../flags/mp.svg'
import mqFlag from './../flags/mq.svg'
import mrFlag from './../flags/mr.svg'
import msFlag from './../flags/ms.svg'
import mtFlag from './../flags/mt.svg'
import muFlag from './../flags/mu.svg'
import mvFlag from './../flags/mv.svg'
import mwFlag from './../flags/mw.svg'
import mxFlag from './../flags/mx.svg'
import myFlag from './../flags/my.svg'
import mzFlag from './../flags/mz.svg'
import naFlag from './../flags/na.svg'
import ncFlag from './../flags/nc.svg'
import neFlag from './../flags/ne.svg'
import nfFlag from './../flags/nf.svg'
import ngFlag from './../flags/ng.svg'
import niFlag from './../flags/ni.svg'
import nlFlag from './../flags/nl.svg'
import noFlag from './../flags/no.svg'
import npFlag from './../flags/np.svg'
import nrFlag from './../flags/nr.svg'
import nuFlag from './../flags/nu.svg'
import nzFlag from './../flags/nz.svg'
import omFlag from './../flags/om.svg'
import paFlag from './../flags/pa.svg'
import peFlag from './../flags/pe.svg'
import pfFlag from './../flags/pf.svg'
import pgFlag from './../flags/pg.svg'
import phFlag from './../flags/ph.svg'
import pkFlag from './../flags/pk.svg'
import plFlag from './../flags/pl.svg'
import pmFlag from './../flags/pm.svg'
import pnFlag from './../flags/pn.svg'
import prFlag from './../flags/pr.svg'
import psFlag from './../flags/ps.svg'
import ptFlag from './../flags/pt.svg'
import pwFlag from './../flags/pw.svg'
import pyFlag from './../flags/py.svg'
import qaFlag from './../flags/qa.svg'
import reFlag from './../flags/re.svg'
import roFlag from './../flags/ro.svg'
import rsFlag from './../flags/rs.svg'
import ruFlag from './../flags/ru.svg'
import rwFlag from './../flags/rw.svg'
import saFlag from './../flags/sa.svg'
import sbFlag from './../flags/sb.svg'
import scFlag from './../flags/sc.svg'
import sdFlag from './../flags/sd.svg'
import seFlag from './../flags/se.svg'
import sgFlag from './../flags/sg.svg'
import shFlag from './../flags/sh.svg'
import siFlag from './../flags/si.svg'
import sjFlag from './../flags/sj.svg'
import skFlag from './../flags/sk.svg'
import slFlag from './../flags/sl.svg'
import smFlag from './../flags/sm.svg'
import snFlag from './../flags/sn.svg'
import soFlag from './../flags/so.svg'
import srFlag from './../flags/sr.svg'
import ssFlag from './../flags/ss.svg'
import stFlag from './../flags/st.svg'
import svFlag from './../flags/sv.svg'
import sxFlag from './../flags/sx.svg'
import syFlag from './../flags/sy.svg'
import szFlag from './../flags/sz.svg'
import taFlag from './../flags/ta.svg'
import tcFlag from './../flags/tc.svg'
import tdFlag from './../flags/td.svg'
import tfFlag from './../flags/tf.svg'
import tgFlag from './../flags/tg.svg'
import thFlag from './../flags/th.svg'
import tjFlag from './../flags/tj.svg'
import tkFlag from './../flags/tk.svg'
import tlFlag from './../flags/tl.svg'
import tmFlag from './../flags/tm.svg'
import tnFlag from './../flags/tn.svg'
import toFlag from './../flags/to.svg'
import trFlag from './../flags/tr.svg'
import ttFlag from './../flags/tt.svg'
import tvFlag from './../flags/tv.svg'
import twFlag from './../flags/tw.svg'
import tzFlag from './../flags/tz.svg'
import uaFlag from './../flags/ua.svg'
import ugFlag from './../flags/ug.svg'
import umFlag from './../flags/um.svg'
import unFlag from './../flags/un.svg'
import usFlag from './../flags/us.svg'
import uyFlag from './../flags/uy.svg'
import uzFlag from './../flags/uz.svg'
import vaFlag from './../flags/va.svg'
import vcFlag from './../flags/vc.svg'
import veFlag from './../flags/ve.svg'
import vgFlag from './../flags/vg.svg'
import viFlag from './../flags/vi.svg'
import vnFlag from './../flags/vn.svg'
import vuFlag from './../flags/vu.svg'
import wfFlag from './../flags/wf.svg'
import wsFlag from './../flags/ws.svg'
import xkFlag from './../flags/xk.svg'
import xxFlag from './../flags/xx.svg'
import yeFlag from './../flags/ye.svg'
import ytFlag from './../flags/yt.svg'
import zaFlag from './../flags/za.svg'
import zmFlag from './../flags/zm.svg'
import zwFlag from './../flags/zw.svg'


function getCountryFlag (countryCode: string) {
    const AVAILABLE_FLAGS: Record<string, string> = {
        acFlag: acFlag,
        adFlag: adFlag,
        aeFlag: aeFlag,
        afFlag: afFlag,
        agFlag: agFlag,
        aiFlag: aiFlag,
        alFlag: alFlag,
        amFlag: amFlag,
        aoFlag: aoFlag,
        aqFlag: aqFlag,
        arFlag: arFlag,
        arabFlag: arabFlag,
        asFlag: asFlag,
        atFlag: atFlag,
        auFlag: auFlag,
        awFlag: awFlag,
        axFlag: axFlag,
        azFlag: azFlag,
        baFlag: baFlag,
        bbFlag: bbFlag,
        bdFlag: bdFlag,
        beFlag: beFlag,
        bfFlag: bfFlag,
        bgFlag: bgFlag,
        bhFlag: bhFlag,
        biFlag: biFlag,
        bjFlag: bjFlag,
        blFlag: blFlag,
        bmFlag: bmFlag,
        bnFlag: bnFlag,
        boFlag: boFlag,
        bqFlag: bqFlag,
        brFlag: brFlag,
        bsFlag: bsFlag,
        btFlag: btFlag,
        bvFlag: bvFlag,
        bwFlag: bwFlag,
        byFlag: byFlag,
        bzFlag: bzFlag,
        caFlag: caFlag,
        ccFlag: ccFlag,
        cdFlag: cdFlag,
        ceftaFlag: ceftaFlag,
        cfFlag: cfFlag,
        cgFlag: cgFlag,
        chFlag: chFlag,
        ciFlag: ciFlag,
        ckFlag: ckFlag,
        clFlag: clFlag,
        cmFlag: cmFlag,
        cnFlag: cnFlag,
        coFlag: coFlag,
        cpFlag: cpFlag,
        crFlag: crFlag,
        cuFlag: cuFlag,
        cvFlag: cvFlag,
        cwFlag: cwFlag,
        cxFlag: cxFlag,
        cyFlag: cyFlag,
        czFlag: czFlag,
        deFlag: deFlag,
        dgFlag: dgFlag,
        djFlag: djFlag,
        dkFlag: dkFlag,
        dmFlag: dmFlag,
        doFlag: doFlag,
        dzFlag: dzFlag,
        eacFlag: eacFlag,
        ecFlag: ecFlag,
        eeFlag: eeFlag,
        egFlag: egFlag,
        ehFlag: ehFlag,
        erFlag: erFlag,
        esFlag: esFlag,
        etFlag: etFlag,
        euFlag: euFlag,
        fiFlag: fiFlag,
        fjFlag: fjFlag,
        fkFlag: fkFlag,
        fmFlag: fmFlag,
        foFlag: foFlag,
        frFlag: frFlag,
        gaFlag: gaFlag,
        gbFlag: gbFlag,
        gdFlag: gdFlag,
        geFlag: geFlag,
        gfFlag: gfFlag,
        ggFlag: ggFlag,
        ghFlag: ghFlag,
        giFlag: giFlag,
        glFlag: glFlag,
        gmFlag: gmFlag,
        gnFlag: gnFlag,
        gpFlag: gpFlag,
        gqFlag: gqFlag,
        grFlag: grFlag,
        gsFlag: gsFlag,
        gtFlag: gtFlag,
        guFlag: guFlag,
        gwFlag: gwFlag,
        gyFlag: gyFlag,
        hkFlag: hkFlag,
        hmFlag: hmFlag,
        hnFlag: hnFlag,
        hrFlag: hrFlag,
        htFlag: htFlag,
        huFlag: huFlag,
        icFlag: icFlag,
        idFlag: idFlag,
        ieFlag: ieFlag,
        ilFlag: ilFlag,
        imFlag: imFlag,
        inFlag: inFlag,
        ioFlag: ioFlag,
        iqFlag: iqFlag,
        irFlag: irFlag,
        isFlag: isFlag,
        itFlag: itFlag,
        jeFlag: jeFlag,
        jmFlag: jmFlag,
        joFlag: joFlag,
        jpFlag: jpFlag,
        keFlag: keFlag,
        kgFlag: kgFlag,
        khFlag: khFlag,
        kiFlag: kiFlag,
        kmFlag: kmFlag,
        knFlag: knFlag,
        kpFlag: kpFlag,
        krFlag: krFlag,
        kwFlag: kwFlag,
        kyFlag: kyFlag,
        kzFlag: kzFlag,
        laFlag: laFlag,
        lbFlag: lbFlag,
        lcFlag: lcFlag,
        liFlag: liFlag,
        lkFlag: lkFlag,
        lrFlag: lrFlag,
        lsFlag: lsFlag,
        ltFlag: ltFlag,
        luFlag: luFlag,
        lvFlag: lvFlag,
        lyFlag: lyFlag,
        maFlag: maFlag,
        mcFlag: mcFlag,
        mdFlag: mdFlag,
        meFlag: meFlag,
        mfFlag: mfFlag,
        mgFlag: mgFlag,
        mhFlag: mhFlag,
        mkFlag: mkFlag,
        mlFlag: mlFlag,
        mmFlag: mmFlag,
        mnFlag: mnFlag,
        moFlag: moFlag,
        mpFlag: mpFlag,
        mqFlag: mqFlag,
        mrFlag: mrFlag,
        msFlag: msFlag,
        mtFlag: mtFlag,
        muFlag: muFlag,
        mvFlag: mvFlag,
        mwFlag: mwFlag,
        mxFlag: mxFlag,
        myFlag: myFlag,
        mzFlag: mzFlag,
        naFlag: naFlag,
        ncFlag: ncFlag,
        neFlag: neFlag,
        nfFlag: nfFlag,
        ngFlag: ngFlag,
        niFlag: niFlag,
        nlFlag: nlFlag,
        noFlag: noFlag,
        npFlag: npFlag,
        nrFlag: nrFlag,
        nuFlag: nuFlag,
        nzFlag: nzFlag,
        omFlag: omFlag,
        paFlag: paFlag,
        peFlag: peFlag,
        pfFlag: pfFlag,
        pgFlag: pgFlag,
        phFlag: phFlag,
        pkFlag: pkFlag,
        plFlag: plFlag,
        pmFlag: pmFlag,
        pnFlag: pnFlag,
        prFlag: prFlag,
        psFlag: psFlag,
        ptFlag: ptFlag,
        pwFlag: pwFlag,
        pyFlag: pyFlag,
        qaFlag: qaFlag,
        reFlag: reFlag,
        roFlag: roFlag,
        rsFlag: rsFlag,
        ruFlag: ruFlag,
        rwFlag: rwFlag,
        saFlag: saFlag,
        sbFlag: sbFlag,
        scFlag: scFlag,
        sdFlag: sdFlag,
        seFlag: seFlag,
        sgFlag: sgFlag,
        shFlag: shFlag,
        siFlag: siFlag,
        sjFlag: sjFlag,
        skFlag: skFlag,
        slFlag: slFlag,
        smFlag: smFlag,
        snFlag: snFlag,
        soFlag: soFlag,
        srFlag: srFlag,
        ssFlag: ssFlag,
        stFlag: stFlag,
        svFlag: svFlag,
        sxFlag: sxFlag,
        syFlag: syFlag,
        szFlag: szFlag,
        taFlag: taFlag,
        tcFlag: tcFlag,
        tdFlag: tdFlag,
        tfFlag: tfFlag,
        tgFlag: tgFlag,
        thFlag: thFlag,
        tjFlag: tjFlag,
        tkFlag: tkFlag,
        tlFlag: tlFlag,
        tmFlag: tmFlag,
        tnFlag: tnFlag,
        toFlag: toFlag,
        trFlag: trFlag,
        ttFlag: ttFlag,
        tvFlag: tvFlag,
        twFlag: twFlag,
        tzFlag: tzFlag,
        uaFlag: uaFlag,
        ugFlag: ugFlag,
        umFlag: umFlag,
        unFlag: unFlag,
        usFlag: usFlag,
        uyFlag: uyFlag,
        uzFlag: uzFlag,
        vaFlag: vaFlag,
        vcFlag: vcFlag,
        veFlag: veFlag,
        vgFlag: vgFlag,
        viFlag: viFlag,
        vnFlag: vnFlag,
        vuFlag: vuFlag,
        wfFlag: wfFlag,
        wsFlag: wsFlag,
        xkFlag: xkFlag,
        xxFlag: xxFlag,
        yeFlag: yeFlag,
        ytFlag: ytFlag,
        zaFlag: zaFlag,
        zmFlag: zmFlag,
        zwFlag: zwFlag
    }
    const requestedFlag = `${countryCode.toLowerCase()}Flag`
    const foundFlag = AVAILABLE_FLAGS[requestedFlag]
    return foundFlag
}
export default getCountryFlag




