import { useEffect, useContext, useState } from 'react'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import SignIn from './SignIn'
import SignUp from './SignUp'
import BalanceSheet from './BalanceSheet'
import Requistions from './Requisitions'
import ProtectedRoute from './ProtectedRoute'
import CloseIcon from '@mui/icons-material/Close'
import { closeSnackbar, SnackbarProvider } from 'notistack'
import Home from './Home'
import Layout from './Layout'
import { Auth } from 'aws-amplify'
import { AppContext } from '../state/AppContext'
import { UserActionTypes } from '../state/UserContext'
import Archive from './Archive'

const router = createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/login',
                element: <SignIn />,
            },
            {
                path: '/signup',
                element: <SignUp />,
            },
            {
                path: '/requisitions',
                element: <ProtectedRoute><Requistions /></ProtectedRoute>,
            },
            {
                path: '/balanceSheet',
                children: [
                    {
                        index: true,
                        element: <ProtectedRoute><BalanceSheet /></ProtectedRoute>
                    },
                    {
                        path:'createARequsitionCallback',
                        element: <ProtectedRoute><BalanceSheet /></ProtectedRoute>
                    }
                ]
            },
            {
                path: '/archive',
                children: [
                    {
                        index: true,
                        element: <ProtectedRoute><Archive /></ProtectedRoute>
                    },
                ]
            },
        ]
    },
   
])
function Main() {
    const { dispatch } = useContext(AppContext)
    const [readyToRender, setReadyToRender] = useState<boolean>(false)

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(r => {
                const { sub, email } = r.attributes
                dispatch({ type: UserActionTypes.SIGN_IN, payload: { sub, email } })
            })
            .catch(e => console.log('Main.tsx:67: ', e))
            .finally(() => setReadyToRender(true))
    }, [])

    return (
        <Container maxWidth={false}>
            <CssBaseline />
            <Box component="main" sx={{ p: 1 }}>
                <Toolbar />
            </Box>
            {readyToRender && <RouterProvider router={router} />}
            <SnackbarProvider action={(snackbarId) => (
                <CloseIcon onClick={() => closeSnackbar(snackbarId)} />
            )} />
        </Container>
    )
}

export default Main