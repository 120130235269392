import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { FormEvent, useContext, useEffect, useRef, useState } from 'react'
import { ArchiveDocumentWithUrl, useGetDocuments } from '../services/Archive'
import { Storage } from 'aws-amplify'
import CircularProgress from '@mui/material/CircularProgress'
import { AppContext } from '../state/AppContext'

function Archive() {
    const { state: { user: { sub } } } = useContext(AppContext)
    const fileFormEl = useRef<HTMLFormElement>(null)
    const [fileToUpload, setFileToUpload] = useState<File | null>(null)
    const [documents, setDocuments] = useState<ArchiveDocumentWithUrl[]>([])
    const getDocuments = useGetDocuments()

    // useEffect(() => {
    //     if (!postDocuments.isSuccess) return

    //     queryClient.setQueryData<QueryResponseBase>(['get-documents'], oldData => {
    //         if (!oldData) return undefined
    //         const mergedDocuments = {
    //             ...oldData, 
    //             Items: [
    //                 ...(oldData?.Items || []), 
    //                 ...postDocuments.data.documents
    //             ] 
    //         }
    //         return mergedDocuments
    //     })

    //     postDocuments.reset()
    // }, [postDocuments])

    useEffect(() => {
        if (!getDocuments.data) return 
        // const urlsToGet = getDocuments.data.filter(remoteDoc => {
        //     const localDoc = documents.find(localDoc => localDoc.id === remoteDoc.id)
        //     return !localDoc || !localDoc.url
        // })

        Promise
            .all(getDocuments.data.map(doc => {
                console.log('Archive.tsx:45: ', doc.id)
                // 55 is private + federated id length 
                return Storage.get(doc.thumbKey.slice(55), { level: 'private' })
            }))
            .then(r=> {
                const docsWithUrls = getDocuments.data.map((doc, i) => {
                    return {
                        ...doc,
                        url: '',
                        thumbUrl: doc.id.includes('.pdf') ? '' : r[i],
                        uploadProgress: 1
                    }
                })
                setDocuments(docsWithUrls)
            })
    }, [getDocuments.data])

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return
        setFileToUpload(e.target.files[0])
    }

    const handleFileSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!fileToUpload) return
        const filename = `userId:${sub}_date:${new Date().toISOString()}_original:${fileToUpload.name}`

        const progressCallback = (p: any) => {
            const progress = p.loaded / p.total

            setDocuments(prev => {
                const documentExisitsInLocalState = prev.find(localDoc => localDoc.id === p.key)

                if (progress === 1) {
                    setTimeout(() => getDocuments.refetch(), 5000)
                    return prev
                }

                if (documentExisitsInLocalState) {
                    const updatedDocuments = prev.map(document => {
                        return document.id === p.key ? {
                            ...document,
                            uploadProgress: progress
                        } : document
                    })
                    return updatedDocuments
                } else {
                    const newDoc = {
                        id: p.key, 
                        url: '', 
                        uploadProgress: progress,
                        createdAt: '', 
                        userId: '',
                        federatedId: '',
                        thumbKey: '',
                        type: '',
                        thumbUrl: '',
                        fields: []
                    }
                    return [...prev, newDoc]
                }
            })
        }

        Storage.vault.put(filename, fileToUpload, { 
            contentType: fileToUpload.type, 
            progressCallback: progressCallback
        })

        if (!fileFormEl.current) return 
        setFileToUpload(null)
        fileFormEl.current.reset()
    }

    const isFileUploading = documents.find(doc => doc.uploadProgress < 1)

    // const unprocessedDocs = documents.filter(doc => !doc.type)
    // const processedDocs = documents.filter(doc => !!doc.type)

    return (
        <Box>
            <Typography variant='h4' textAlign='center'>Archive</Typography>
            <Box 
                component='form'
                onSubmit={handleFileSubmit}
                ref={fileFormEl}
                display='flex'
            >
                <TextField type="file" onChange={handleFileChange}/>
                <Button 
                    type='submit'
                    variant='contained'
                    size='large'
                    sx={{ ml: 1 }}
                    disabled={!!isFileUploading}
                >Upload</Button>
            </Box>
            {/* <Box>
                {unprocessedDocs.length > 0 && (unprocessedDocs.map(doc => (
                    <Box key={doc.id} display='flex' flexDirection='row'>
                        <img src={doc.url} width={150} height={150}/>
                    </Box>
                )))}
            </Box> */}
            <Box>
                {documents.length > 0 && ([...documents].reverse().map((doc => (
                    <Box key={doc.id} display='flex' flexDirection='row'>
                        {doc.uploadProgress < 1 ? (
                            <Box p={2}>
                                <CircularProgress 
                                    variant="determinate" 
                                    value={doc.uploadProgress * 100}
                                    size={120}
                                    sx={{ width: '100%', height: '100%' }} 
                                />  
                            </Box>
                        ): (
                            <img src={doc.thumbUrl} width={150} height={150}/>
                        )}
                        <Typography key={doc.id}>
                            {doc.createdAt && doc.createdAt + ', '}{doc.id}
                        </Typography>
                    </Box>
                ))))}
                {documents.length === 0 && (
                    <Typography>no documents uploaded yet</Typography>
                )}
            </Box>
        </Box>
    )
}

export default Archive