import { Outlet } from 'react-router-dom'
import GlobalHeader from './GlobalHeader'

function Layout () {
    return (
        <>
            <GlobalHeader />
            <Outlet />
        </>
    )
}

export default Layout