import { useEffect, useState } from 'react'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Institution, useGetInstitutions, useCreateRequisition } from '../services/GoCardless'
import Box from '@mui/material/Box'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { alpha, useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import { AVAILABLE_LANGUAGES, Language, Country, COUNTRIES_WITH_AVAILABLE_INSTITUTIONS } from '../consts'
import getCountryFlag from './getCountryFlag'


function LinkAccounts() {
    const theme = useTheme()
    const browserLanguage = navigator.language.slice(0,2).toUpperCase()
    const sortedLanguages = [
        AVAILABLE_LANGUAGES.find(l => l.id === browserLanguage), 
        ...AVAILABLE_LANGUAGES.filter(l => l.id !== browserLanguage)
    ].filter(l => !!l) as Language[]

    const [activeStep, setActiveStep] = useState(0)

    const [chosenCountry, setChosenCountry] = useState<Country | null>(null)
    const [countryInputValue, setCountryInputValue] = useState<string>('')

    const [chosenInstitution, setChosenInstitution] = useState<Institution | null>(null)
    const [institutionInputValue, setInstitutionInputValue] = useState<string>('')

    const [chosenLanguage, setChosenLanguage] = useState<Language | null>(null)
    const [languageInputValue, setLanguageInputValue] = useState<string>('')

    const getInstitutions = useGetInstitutions(chosenCountry?.countryCode as string)
    const createRequisition = useCreateRequisition()

    useEffect(() => {
        if (chosenCountry) getInstitutions.refetch()
        if (!chosenCountry) {
            setChosenInstitution(null)
            createRequisition.reset()
        }
    }, [chosenCountry])

    useEffect(() => {
        if (chosenCountry && chosenInstitution && chosenLanguage) {
            createRequisition.mutate({
                institutionId: chosenInstitution?.id as string, 
                language: chosenLanguage?.id as string,
                country: chosenCountry?.countryCode as string, 
                institutionName: chosenInstitution?.name as string
            })
        }
    }, [chosenCountry, chosenInstitution, chosenLanguage])

    const onCountryDelete = () => {
        setChosenCountry(null)
        setChosenInstitution(null)
        setChosenLanguage(null)
        setActiveStep(0)
    }

    const onInstituitionDelete = () => {
        setChosenInstitution(null)
        setChosenLanguage(null)
        setActiveStep(1)
    }

    const onLanguageDelete = () => {
        setChosenLanguage(null)
        setActiveStep(2)
    }

    const steps = [
        {
            labelNode: (
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Typography>
                        {chosenCountry? 'Selected country' : 'Select the country of the bank'} </Typography>
               
                    {chosenCountry && (
                        <Chip
                            avatar={
                                <Avatar 
                                    alt={`flag of ${chosenCountry.country}`} 
                                    src={getCountryFlag(chosenCountry.countryCode)} 
                                />}
                            label={chosenCountry.country}
                            variant= 'filled'
                            sx={{
                                m: 1,
                                border: 1,
                                borderColor: 'secondary.main',
                            }}
                            onDelete={onCountryDelete}
                        />
                    )}
                </Box>
            ),
            content: (
                <Box>
                    <Typography>
                        Start with selecting the country of your bank.
                    </Typography>
                    <TextField 
                        id='country' 
                        label='search for a country...' 
                        value={countryInputValue} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCountryInputValue(event.target.value)
                        }}
                        sx={{ width: '100%' }}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    sx={{ visibility: countryInputValue ? 'visible' : 'hidden' }}
                                    onClick={() => setCountryInputValue('')}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                    />

                    {COUNTRIES_WITH_AVAILABLE_INSTITUTIONS
                        .filter(country => `${country.country}${country.countryCode}`.toLowerCase().includes(countryInputValue.toLowerCase()))
                        .map(country => (
                            <Chip
                                key={country.countryCode}
                                avatar={
                                    <Avatar 
                                        alt={`flag of ${country.country}`} 
                                        src={getCountryFlag(country.countryCode)} 
                                    />}
                                label={country.country}
                                variant= 'outlined'
                                sx={{ m: 1 }}
                                onClick={() => {
                                    setChosenCountry(country)
                                    setActiveStep(1)
                                }}
                            />
                        ))
                    }

                </Box>
            )
        },
        {
            labelNode: (
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Typography>
                        {chosenInstitution? 'Selected bank' : 'Select the bank'}
                    </Typography>
               
                    {chosenInstitution && (
                        <Chip
                            avatar={
                                <Avatar 
                                    alt={`flag of ${chosenInstitution.name}`} 
                                    src={chosenInstitution.logo} 
                                />}
                            label={chosenInstitution.name}
                            variant= 'filled'
                            sx={{
                                m: 1,
                                border: 1,
                                borderColor: 'secondary.main',
                            }}
                            onDelete={onInstituitionDelete}
                        />
                    )}
                </Box>
            ),
            content: (
                <Box>
                    {getInstitutions.isLoading && (
                        <Typography>Loading banks in {chosenCountry?.country}...</Typography>
                    )}
                    {!chosenInstitution && getInstitutions.data && (
                        <>
                            <TextField 
                                id='filterInstitutions' 
                                label='search for a bank' 
                                value={institutionInputValue} 
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setInstitutionInputValue(event.target.value)
                                }}
                                sx={{ width: '100%' }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            sx={{ visibility: countryInputValue ? 'visible' : 'hidden' }}
                                            onClick={() => setInstitutionInputValue('')}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    ),
                                }}
                            />
                            {getInstitutions.data
                                .filter(institution => `${institution.name}${institution.id}`.toLowerCase().includes(institutionInputValue.toLowerCase()))
                                .map(institution => (
                                    <Chip 
                                        key={institution.id}
                                        sx={{ m: 1 }}
                                        variant='outlined'
                                        label={institution.name} 
                                        onClick={() => {
                                            setChosenInstitution(institution)
                                            setActiveStep(2)
                                        }}
                                    />                                    
                                ))}
                        </>
                        
                    )}
                </Box>
            )
        },
        {
            labelNode: (
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Typography>
                        {chosenLanguage? 'Selected language' : 'Select your language'} </Typography>
               
                    {chosenLanguage && (
                        <Chip
                            label={chosenLanguage.name}
                            variant= 'filled'
                            sx={{
                                m: 1,
                                border: 1,
                                borderColor: 'secondary.main',
                            }}
                            onDelete={onLanguageDelete}
                        />
                    )}
                </Box>
            ),
            content: (
                <Box>
                    <TextField 
                        id='filter-languages' 
                        label='search for a language' 
                        value={languageInputValue} 
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setLanguageInputValue(event.target.value)
                        }}
                        sx={{ width: '100%' }}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    sx={{ visibility: countryInputValue ? 'visible' : 'hidden' }}
                                    onClick={() => setLanguageInputValue('')}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                    />

                    {sortedLanguages
                        .filter(language => `${language.id}${language.name}`.toLowerCase().includes(languageInputValue.toLowerCase()))
                        .map(language => (
                            <Chip
                                key={language.name}
                                label={language.name}
                                variant= 'outlined'
                                sx={{ m: 1 }}
                                onClick={() => {
                                    setChosenLanguage(language)
                                    setActiveStep(3)
                                }}
                            />
                        ))
                    }

                </Box>
            )
        },
        {
            labelNode: (
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Typography>Data link</Typography>
                </Box>),
            content: (
                <>
                    {chosenInstitution && (
                        <Stack justifyContent='center' alignItems='center'>
                            {createRequisition.isLoading && (
                                <Typography>We are preparing data link to {chosenInstitution.name}. This may take a moment...</Typography>
                            )}

                            {!createRequisition.isLoading && createRequisition.data && (
                                <>
                                    <Typography>Click the button below to create data link.</Typography>
                                    <Button 
                                        variant='contained'
                                        href={createRequisition.data.link}
                                        sx={{ mt: 1 }}
                                    >
                               Link your accounts in {chosenInstitution.name}
                                    </Button>
                                </>
                            )}
                                             
                        </Stack>
                    )}
                </>
            )
        },
    ]

    const stepperOrientation = useMediaQuery(theme.breakpoints.up('sm')) ? 'horizontal' : 'vertical'

    return (
        <Box>
            <Typography variant='h5' textAlign='center'>Link your accounts</Typography>
            <Typography>The form below links your accounts in EU and UK banks to radix. </Typography>
            <Typography>You may sever the link and will permanently erase your data on radix servers at any time.</Typography>
            <Typography>The linking process is handled by GoCardless. GoCardless will display all details about the link and take you to your bank. You can verify the bank website by inspecting the URL and the certificate (padlock icon).</Typography>

            <Box sx={{
                border: 2,
                borderColor: 'primary.main',
                borderRadius: 2,
                p: 1,
                mt: 1
            }}>
                <Stepper 
                    activeStep={activeStep} 
                    orientation={stepperOrientation}
                    sx={{
                        py: 1,
                        mb: 1,
                        bgcolor: alpha(theme.palette.primary.light, 0.3),
                        borderRadius: 2
                    }}>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>
                                {step.labelNode}
                            </StepLabel>
                        </Step>
                    ))}                
                </Stepper>

                <Box sx={{
                    border: 1,
                    borderColor: 'secondary.main',
                    borderRadius: 2,
                    p: 1
                }}>
                    {steps[activeStep].content}
                </Box>
            </Box>

        </Box>
    )
    
}

export default LinkAccounts
