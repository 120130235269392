import { ThemeProvider, createTheme } from '@mui/material/styles'
import { AppProvider } from '../state/AppContext'
import Main from './Main'
import useThemeDetector from '../hooks/useThemeDetector'

function App() {
    const isDarkTheme = useThemeDetector()
    const sharedThemeProps = { typography: { fontFamily: ['Lato', 'sans-serif'].join(',') } }

    const darkTheme = createTheme({
        ...sharedThemeProps,
        palette: { mode: 'dark', },
    })
    const lightTheme = createTheme({
        ...sharedThemeProps,
        palette: { mode: 'light', },
    })

    return (
        <>
            <AppProvider>
                <ThemeProvider theme={isDarkTheme ? darkTheme: lightTheme}>
                    <Main/>
                </ThemeProvider>
            </AppProvider>
        </>
    )
}
export default App
