import Box from '@mui/material/Box'
import Accounts from './Accounts'
import LinkAccounts from './LinkAccounts'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useEffect, useState } from 'react'
import AccountForm from './AccountForm'
import AddIcon from '@mui/icons-material/Add'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useExtractRequisition } from '../services/GoCardless'
import { useQueryClient } from '@tanstack/react-query'

function BalanceSheet() {
    const [value, setValue] = useState(0)
    const queryClient = useQueryClient()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const ref = new URLSearchParams(location.search).get('ref')
    const error = new URLSearchParams(location.search).get('error')
    const details = new URLSearchParams(location.search).get('details')
    const extractRequisition = useExtractRequisition(ref as string)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    useEffect(() => {
        if (!ref || error) return
        extractRequisition.refetch()
    }, [ref])


    const a11yProps = (index: number) => {
        return {
            'id': `add-account-${index}`,
            'aria-controls': `add-account-tabpanel-${index}`,
        }
    }

    useEffect(() => {
        if (!extractRequisition.data) return
        queryClient.invalidateQueries({ queryKey: ['get-balances'] })
        queryClient.invalidateQueries({ queryKey: ['get-accounts'] })
        searchParams.delete('ref')
        setSearchParams(searchParams)

    }, [extractRequisition.data])

    return (
        <Box>
            <Typography variant='h4' textAlign='center'>Balance Sheet</Typography>
            {ref && (
                <Box sx={{ mb: 1 }}>
                    {ref && extractRequisition.isLoading && (
                        <Typography>
                    We are preparing your data, this may take a moment...
                        </Typography>
                    )}
                    {error && (
                        <Typography>
                    Authorization is unsuccesfull. Reason: {details}
                        </Typography>
                    )}
                    {extractRequisition.data && (
                        <>
                            <Typography>Success! You should se your newly linked accounts down below in a moment.</Typography>
                        </>
                    )}
                </Box>
            )}
            
            <Accordion sx={{ mb: 1 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="link-accounts-content"
                    id="link-accounts-header"
                >
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <AddIcon sx={{ mr: 1 }} /> 
                        <Typography>Add an account</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs centered value={value} onChange={handleChange} 
                            aria-label="add an account"
                            variant='fullWidth'
                        >
                            <Tab label="Manual" 
                                {...a11yProps(0)} 
                            />
                            <Tab label="Link your accounts" 
                                {...a11yProps(1)} 
                            />
                        </Tabs>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        {value === 0 ? (
                            <AccountForm />
                        ): (
                            <LinkAccounts />
                        )}
                    </Box>
                 
                </AccordionDetails>
            </Accordion>   
            <Accounts />
        </Box>
    )
}

export default BalanceSheet