import { useMutation, useQuery } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import { QueryResponseBase } from './types'

export type Balance = {
    accountId: string,
    userId: string,
    balanceAmount: {
        amount: string
        currency: string
    },
    balanceType: string,
    creditLimitIncluded?: string,
    lastChangeDateTime?: string,
    referenceDate?: string,
    lastCommittedTransaction?: string,
}

export const getBalances = async (): Promise<QueryResponseBase> => {
    return API.get('balance-sheet', 'accountBalances', {})
}

export const useGetBalances = () => {
    return useQuery<QueryResponseBase, Error, any[]>({
        queryKey: ['get-balances'],
        queryFn: () => getBalances(),
        select: r => r.Items
    })
}
export const postBalances = async (params: any) => {
    return API.post('balance-sheet', 'accountBalances', { body: { balances: [params] } })
}

export const usePostBalances = () => {
    return useMutation<{balances: Balance[]}, Error, {
        balanceAmount: string,
        balanceType: string,
        accountId: string,
        currency: string
    }>({ mutationFn: (params) => postBalances(params), })
}

export const getRequisitions = async () => {
    return API.get('balance-sheet', 'requisitions', {})
}

export const useGetRequisitions = () => {
    return useQuery<QueryResponseBase, Error, any[]>({
        queryKey: ['get-requisitions'],
        queryFn: () => getRequisitions(),
        select: r => r.Items
    })
}
export const getAccounts = async () => {
    return API.get('balance-sheet', 'accounts', {})
}

export const useGetAccounts = () => {
    return useQuery<QueryResponseBase, Error, any[]>({
        queryKey: ['get-accounts'],
        queryFn: () => getAccounts(),
        select: r => r.Items
    })
}
export const postAccounts = async (params: any) => {
    return API.post('balance-sheet', 'accounts', { body: { ...params } })
}

export const usePostAccounts = () => {
    return useMutation<QueryResponseBase, Error, any>({ mutationFn: (params) => postAccounts(params) })
}

export const deleteBalances = async (params: any) => {
    return API.del('balance-sheet', 'accountBalances', { body: { ...params } })
}

export const useDeleteBalances = () => {
    return useMutation<QueryResponseBase, Error, {
        accountIds: string,
    }>({ mutationFn: (params) => deleteBalances(params), })
}

export const deleteAccounts = async (params: any) => {
    return API.del('balance-sheet', 'accounts', { body: { ...params } })
}

export const useDeleteAccounts = () => {
    return useMutation<QueryResponseBase, Error, {accountIds: string[]}>({ mutationFn: (params) => deleteAccounts(params) })
}