import { Auth } from 'aws-amplify'
import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const ProtectedRoute = ({ children } : {children: ReactNode}) => {
    const navigate = useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const checkIsUserAuthenticated = async () => {
        try {
            await Auth.currentAuthenticatedUser()
            setIsLoggedIn(true)
        } catch (e) {
            setIsLoggedIn(false)
            return navigate('/login/')
        }
    }
    useEffect(() => {
        checkIsUserAuthenticated()
    }, [isLoggedIn])

    return (
        <React.Fragment>
            {isLoggedIn ? children : null}
        </React.Fragment>
    )
}
export default ProtectedRoute