const config = {
    s3: {
        REGION: process.env.REACT_APP_REGION,
        FE_BUCKET: process.env.REACT_APP_FE_BUCKET,
        ARCHIVE_BUCKET: process.env.REACT_APP_ARCHIVE_BUCKET,
    },
    apiGateway: {
        REGION: process.env.REACT_APP_REGION,
        URL_LINKED_BALANCE_SHEET_: process.env.REACT_APP_BALANCE_SHEET_API_URL,
        URL_GO_CARDLESS: process.env.REACT_APP_GO_CARDLESS_API_URL,
        URL_FX_API: process.env.REACT_APP_FX_API_URL,
        URL_ARCHIVE_API: process.env.REACT_APP_ARCHIVE_API,
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
}
  
export default config
  